import {
  AppBar,
  Box,
  Toolbar,
  Typography,
  Menu,
  Divider,
  MenuItem,
  IconButton,
  Badge,
} from "@mui/material";
import { useStyle } from "./header.style";
import { memo, useEffect, useState } from "react";
import { mediaQueryXs } from "../services/media-query-xs";
import aadService from "../authProvider";
import { fullName } from "../services/session";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import CloseIcon from "../../src/icons/closeIcon.svg";

const menuContent = {
  marginTop: "10px",
  "& .menuItems": {
    padding: "13px",
    cursor: "pointer",
  },
};

interface Notification {
  id: string;
  title: string;
  message: string;
  details: string;
  recipientEmail: string;
  sent: boolean;
  createdAt: string;
}

interface HeaderProps {
  isLogin: boolean;
}

const Header: React.FC<HeaderProps> = ({ isLogin }) => {
  const userName = fullName;
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [anchorElNotification, setAnchorElNotification] = useState<null | HTMLElement>(null);
  const [unreadCount, setUnreadCount] = useState<number>(3);
  const [notifications, setNotifications] = useState<Notification[]>([]);

  const open = Boolean(anchorEl);
  const openNotification = Boolean(anchorElNotification);
  const logo = mediaQueryXs() ? "/assets/logo.png" : "/assets/cavinLogo.svg";

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickSignout = () => {
    aadService.logout();
  };



  const handleNotificationClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNotification(event.currentTarget);
    setUnreadCount(0);
  };

  const handleNotificationClose = () => {
    setAnchorElNotification(null);
  };

  // const handleMarkAllRead = () => {
  //   setUnreadCount(0);
  //   handleNotificationClose();
  // };

  const headerConfig: { [key: string]: { showNotifications: boolean; title: string } } = {
    "/productView": {
      showNotifications: true,
      title: "PACKAGING LIBRARY"
    },
    "/": {
      showNotifications: false,
      title: ""
    },
    "/barcodeRequest": {
      showNotifications: true,
      title: "BARCODE MANAGEMENT"
    },
    "/barcodeList": {
      showNotifications: true,
      title: "BARCODE MANAGEMENT"
    },
    "/barcodeListEmp": {
      showNotifications: true,
      title: "BARCODE MANAGEMENT"
    },
    // "/addVendor": {
    //   showNotifications: true,
    //   title: "PACKAGING LIBRARY"
    // },
  };


  const currentHeaderConfig = headerConfig[location.pathname] || headerConfig["/productView"];

  const getNotification = async () => {
    const email = sessionStorage.getItem('email');
    const token = sessionStorage.getItem('AccessToken');
    try {
      const res = await axios.get(`https://packaging.ckdigital.in/api/v1/barcodes/getNotification?recipientEmail=${email}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        }
      );
      setNotifications(res.data);
    } catch (error) {
      console.error('Error fetching notifications:', error);
    }
  };

  useEffect(() => {
    if (isLogin) {
      getNotification();
    }
  }, [isLogin]);

  return (
    <AppBar id="header-content" sx={useStyle} position="fixed">
      <Toolbar>
        <div style={{ display: "flex", justifyContent: "flex-start" }}>
          <div onClick={() => (window.location.href = "/")}>
            <img
              className="logo-wrapper"
              src={logo}
              alt="cavinlogo"
              width={mediaQueryXs() ? 156 : 45}
              height={mediaQueryXs() ? 50 : 30}
            />
          </div>
          <Divider
            orientation="vertical"
            style={{
              margin: "9px 35px",
              border: "1px solid #3161BE80",
              height: "6vh",
              alignItems: "center",
            }}
          />
          <Box sx={{ display: { lg: "flex" }, alignItems: "center" }}>
            <Typography
              style={{
                fontFamily: "Montserrat",
                fontSize: "20px",
                fontWeight: "600",
                lineHeight: "30px",
                letterSpacing: "0.1em",
                textAlign: "left",
                color: "#3161BE",
              }}
              component="div"
            >
              {currentHeaderConfig.title}
            </Typography>
          </Box>
        </div>
        {/* {currentHeaderConfig.showNotifications && ( */}
        <Box sx={{ display: { lg: "flex" }, justifyContent: "flex-end" }}>
          {currentHeaderConfig.showNotifications && (
            <IconButton onClick={handleNotificationClick}>
              <Badge
                color="error"
                variant="dot"
                invisible={(unreadCount === 0) || (notifications.length === 0)}
              >
                <img
                  src="/assets/notification_icon.svg"
                  alt="notifications"
                  width={30}
                  height={30}
                />
              </Badge>
            </IconButton>
          )}
          &nbsp;&nbsp;&nbsp;
          <IconButton onClick={handleClick}>
            <img src="/assets/Avatar.png" alt="avatar" width={33} height={33} />
          </IconButton>
          &nbsp;&nbsp;
          {mediaQueryXs() && (
            <Box display="flex" flexDirection="column">
              <Typography style={{ fontWeight: '600', color: '#27232A' }} variant="body1">
                {sessionStorage.getItem("fullName")}
              </Typography>
              <Typography style={{ color: '#9A9A9A' }} variant="body2">
                {sessionStorage.getItem("role")}
              </Typography>
            </Box>
          )}
        </Box>
        {/* )} */}
      </Toolbar>
      <Menu
        sx={menuContent}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <Typography
          className="menuItems"
          variant="body2"
          onClick={handleClickSignout}
        >
          Sign Out
        </Typography>
      </Menu>
      <Menu
        id="notification-menu"
        anchorEl={anchorElNotification}
        open={openNotification}
        onClose={handleNotificationClose}
        sx={{
          '& .css-6hp17o-MuiList-root-MuiMenu-list': {
            paddingTop: "0px",
            maxHeight: 350,
            minHeight: 100,
          }
        }}

      >
        {notifications.length > 0 && (
          <Box
            display="flex"
            justifyContent="flex-end"
            p={1}
            sx={{
              position: 'sticky',
              top: 0,
              backgroundColor: 'white',
              zIndex: 1,
              borderBottom: "1px solid #eee"
            }}
          >
            {/* <Typography
              variant="subtitle1"
              onClick={handleMarkAllRead}
              style={{ cursor: "pointer" }}
            >
              Mark all read
            </Typography> */}
            <Box display="flex" alignItems="center" style={{ cursor: "pointer", color: "red" }} onClick={handleNotificationClose}>
              <Typography variant="body1" style={{ cursor: "pointer", color: "red" }}>
                Close
              </Typography>
              <img src={CloseIcon} style={{ marginLeft: '5px' }} />
            </Box>
          </Box>
        )}
        {notifications.length > 0 ? (
          notifications.map((n, index) => (
            <MenuItem key={index}>
              <Box display="flex" flexDirection="column">
                <Typography variant="body1">
                  <strong>{n.title}</strong>
                </Typography>
                <Typography variant="body2">{n.message}</Typography>
                <Typography variant="body2"><b>{n.details}</b></Typography>
              </Box>
            </MenuItem>
          ))
        ) : (
          <MenuItem>
            <Box display="flex" justifyContent="center" alignItems="center" height="100%">
              <Typography variant="body2">No notifications</Typography>
            </Box>
          </MenuItem>
        )}
      </Menu>
    </AppBar>
  );
};

export default memo(Header);
